/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from 'react'
import moment from 'moment'
import { graphql } from 'gatsby'
// import { css, jsx } from '@emotion/core'
// import { themeGet } from 'styled-system'
import { css } from '@emotion/core'

import createAnimation from '../types/animation'
import {
  Page,
  Flex,
  Button,
  Text,
  FeatherIcon,
  // H1,
  Hr,
  Image,
  Link,
  // Circle,
  Box,
  Truncate,
  // Paper,
  // H2,
} from '../elements'
import {
  // BoardgamePreview,
  // PeoplePreview,
  TournamentPrizes,
  Description,
  More,
  AnimationCategories,
  Timing,
  TournamentDetails,
  FacebookCircle,
  HeadingComplex,
  // HeadingPaperSection,
  SectionBoardgames,
  SectionGuests,
  SectionExhibitors,
  Youtube,
  Album,
  // ExhibitorEntry,
  SEO,
  PlaceIcon,
} from '../components'
// import { AutoHeight } from '../containers'
import { happenOnDay } from '../utils'

const AnimationTemplate = ({ data: { animation } }) => {
  // TODO: find why there is an entry where data.animation is null
  if (!animation) {
    return null
  }

  const {
    relativePath,
    nameCanonical,
    name,
    nameFallback,
    days,
    start,
    end,
    schedule: sched,
    featuredImage,
    maxParticipants,
    tournamentPrizes,
    tournamentRules,
    tournamentBookingLink,

    categories,
    facebook,
    website,
    youtubeVideo: { id: videoId },
    boardgames,
    exhibitors,
    guests,
    description,
    more,
    price,
    place,
    album,
    // fridayIsException,
  } = createAnimation(animation)

  const schedule = JSON.parse(sched)
  const scheduleGroups = schedule.groups

  const boardgameImage = boardgames && boardgames[0].featuredImage

  const url =
    website && website.substring(0, 4) === 'http'
      ? website
      : `http://${website}`

  // const category = categories[0]
  const isNight = categories.find(cat => Boolean(cat.match(/night/)))
  const isTournament = categories.find(cat => cat === 'tournaments')
  const hasDetails = !!(tournamentRules || maxParticipants || price)
  const mainColor = isTournament ? 'tournament' : 'animation'

  return (
    <Page
      {
        ...{
          // className: css`
          //   & > * {
          //     max-width: ${themeGet('site', 'maxWidth')};
          //   }
          // `,
        }
      }
    >
      {({ t, currentLocale }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `${relativePath}`,
              title: nameFallback[currentLocale],
              // description: true,
            }}
          />
          <HeadingComplex
            {...{
              type: isTournament ? 'tournament' : 'animation',
              // icon: 'animation',
              // iconColor: isTournament ? 'tournament' : 'animation',
              nameCanonical,
              name: nameFallback,
              // place,
            }}
          />

          {!(isTournament || hasDetails) && featuredImage && (
            <Image
              {...{
                featuredImage,
                // src: featuredImage.thumbnails.large.url,
                width: '100%',
                height: [100, 140, 180],
                // objectFit: `contain`,
              }}
            />
          )}
          {/* <Hr {...{ width: 0 }} /> */}
          {(isTournament || hasDetails) && (
            <Flex.container
              {...{
                width: '100%',
                // maxwidth: '100%',
                color: 'textAlt',
                bg: mainColor,
              }}
            >
              <Box
                {...{
                  bg: 'white',
                  size: 100,
                  p: 1,
                  mr: 2,
                  borderRadius: 'mediumFeaturedImage',
                  css: {
                    flexShrink: 0,
                  },
                }}
              >
                <Image
                  {...{
                    featuredImage: boardgameImage || featuredImage,
                    // src: featuredImage.thumbnails.large.url,
                    size: '100%',
                    borderRadius: 'mediumFeaturedImage',
                    objectFit: 'contain',
                  }}
                />
              </Box>

              <Box
                {...{
                  width: '100%',
                }}
              >
                <TournamentDetails
                  {...{
                    tournamentRules:
                      t(tournamentRules) ||
                      t(tournamentRules, 'fr') ||
                      tournamentRules,
                    maxParticipants,
                    price,
                    color: 'textAlt',
                    t,
                  }}
                />
              </Box>
            </Flex.container>
          )}

          {// start && end &&
          !!Object.keys(scheduleGroups).length && (
            <React.Fragment>
              {/* <Hr
                {...{
                  mb: 0,
                }}
              /> */}
              <Flex.container
                {...{
                  py: 3,
                  width: '100%',
                  bg: mainColor,
                  color: 'textAlt',
                  alignItems: 'center',
                }}
              >
                <FeatherIcon
                  {...{
                    icon: isNight ? 'moon' : 'sun',
                    color: 'textAlt',
                    fill: 'textAlt',
                    size: 4,
                    // height: 30,
                    // width: 30,
                    mr: 2,
                    // mb: 1,
                  }}
                />
                <Flex
                  {...{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    // alignContent: 'center',
                    css: {
                      flexGrow: 1,
                    },
                  }}
                >
                  {Object.entries(schedule).map(
                    ([day, { timesString }]) => {
                      return (
                        timesString && (
                          <Flex
                            {...{
                              key: day,
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Text
                              {...{
                                // color: 'textAlt',
                                fontSize: 1,
                                fontWeight: 'bold',
                                color: 'textAlt',
                              }}
                            >
                              {`${t(day).substring(0, 3)}: `}
                            </Text>
                            <Text
                              {...{
                                m: null,
                                ml: 1,
                                fontSize: 1,
                                color: 'textAlt',
                              }}
                            >
                              {timesString}
                            </Text>
                          </Flex>
                        )
                      )
                    },
                    /*
                    happenOnDay({ days, day, start, end }) ? (
                      <Text
                        {...{
                          key: day,
                          fontSize: 1,
                          fontWeight: 'bold',
                          color: 'textAlt',
                          css: css`
                            display: flex;
                            justify-content: space-between;
                          `,
                        }}
                      >
                        {t(day).substring(0, 3)}:{' '}
                        <Timing
                          {...{
                            start:
                              day === 'friday' && fridayIsException
                                ? moment(start).hour(15)
                                : start,
                            end,
                            color: 'textAlt',
                          }}
                        />
                      </Text>
                    ) : null,
                    */
                  )}
                </Flex>
                <PlaceIcon
                  {...{
                    // icon: 'animation',
                    place,
                    tPlace: t(place),
                    color: mainColor,
                  }}
                />
              </Flex.container>
              {/* <Hr {...{ mt: 0 }} /> */}
            </React.Fragment>
          )}

          {tournamentBookingLink && (
            <Box.container
              {...{
                bg: mainColor,
                p: null,
                pb: 3,
              }}
            >
              <Link
                {...{
                  href: tournamentBookingLink,
                }}
              >
                <Button
                  {...{
                    display: 'block',
                    border: 'button',
                    borderRadius: 3,
                    borderColor: 'textAlt',
                    bg: mainColor,
                    color: 'textAlt',
                    m: 'auto',
                    // mt: 2,
                    // mb: 0,
                    py: 2,
                    css: {
                      textTransform: 'uppercase',
                    },
                  }}
                >
                  {t('registration')}
                </Button>
              </Link>
            </Box.container>
          )}

          <AnimationCategories
            {...{
              categories,
              bg: mainColor,
              color: 'textAlt',
              fontSize: 1,
              mt: 2,
              mb: 1,
              container: true,
            }}
          />

          <Flex
            {...{
              justifyContent: 'space-between',
              alignItems: 'center',
              px: [2, 2, 3],
              my: 2,
            }}
          >
            <Truncate {...{}}>
              {website && (
                <Link
                  {...{
                    href: url,
                    target: '_blank',
                    rel: 'noopener noreferrer',

                    color: mainColor,
                    fontSize: [1, 1, 2],
                  }}
                >
                  {website}
                </Link>
              )}
            </Truncate>
            <FacebookCircle
              {...{
                color: 'textAlt',
                backgroundColor: mainColor,
                link: facebook,
              }}
            />
          </Flex>

          {tournamentPrizes && (
            <TournamentPrizes
              {...{ prizes: tournamentPrizes, color: mainColor }}
            />
          )}

          <Description
            {...{
              description,
              color: mainColor,
            }}
          />

          {!!(album && album.length) && (
            <Flex.container>
              <Album {...{ album }} />
            </Flex.container>
          )}
          {/* {album && (
            <React.Fragment>
              <Hr {...{}} />
              <Flex.container>
                <Album {...{ album }} />
              </Flex.container>
            </React.Fragment>
          )} */}

          {videoId && (
            <Box.container
              {...{
                width: '100%',
                maxWidth: [300, 300, 600],
                // height: '100%',
                height: [300 / 1.777, 300 / 1.777, 600 / 1.777],
                // alignText: 'center',
                m: 'auto',
              }}
            >
              <Youtube
                {...{
                  videoId,
                  css: {
                    width: '100%',
                    height: '100%',
                  },
                }}
              />
            </Box.container>
          )}

          {false && more && more.fr && (
            <React.Fragment>
              {/* <Hr {...{}} /> */}
              <More
                {...{
                  more: more[currentLocale] || more.fr,
                  color: mainColor,
                }}
              />
            </React.Fragment>
          )}

          <SectionBoardgames {...{ boardgames, t }} />
          <SectionExhibitors {...{ exhibitors, t }} />
          <SectionGuests {...{ guests, t }} />
        </React.Fragment>
      )}
    </Page>
  )
}

export const pageQuery = graphql`
  query Animation($id: String!) {
    animation(id: { eq: $id }) {
      relativePath
      nameCanonical
      name {
        en
        fr
        nl
      }
      nameFallback {
        en
        fr
        nl
      }
      description {
        en
        fr
        nl
      }
      more {
        en
        fr
        nl
      }
      website
      facebook
      youtubeVideo {
        id
        url
        opts {
          startSeconds
        }
      }
      place
      price
      maxParticipants
      days
      start
      end
      complexSchedule {
        Start
        End
      }
      schedule
      fridayIsException
      categories
      tournamentPrizes {
        en
        fr
        nl
      }
      tournamentRules
      tournamentBookingLink
      featuredImage {
        id
        url
        size
        thumbnails {
          small {
            height
            width
            url
          }
          large {
            url
            width
            height
          }
        }
      }

      album {
        id
        url
        size
        thumbnails {
          small {
            url
            width
            height
          }
          large {
            url
            width
            height
          }
          full {
            url
            width
            height
          }
        }
      }

      boardgames {
        relativePath
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        pitch {
          en
          fr
          nl
        }
        duration
        accessibility
        age
        audience
        maxPlayers
        minPlayers
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      exhibitors {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        category
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      guests {
        nameCanonical
        name {
          fr
          en
          nl
        }
        nameFallback {
          fr
          en
          nl
        }
        status
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }
    }
  }
`

export default AnimationTemplate
